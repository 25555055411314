.home {
    padding-top: 45px;

    .slider {
        width: 1220px;
        margin-left: -25px;
        margin-right: -25px;
        background-image:url('../../assets/images/home/slider.jpg');
        height: 380px;
        background-size: cover;
        box-sizing: border-box;

        .intro-text {
            width: 550px;
            margin-left: 25px;
            padding-top: 110px;

            p {
                padding: 0;
                font-size: 28px;
                color: white;
                font-weight: 200;
            }
        }
    }

    h1 {        
        font-weight: 300;
        margin-top: 40px;
        margin-bottom: 30px;
        position: relative;

        &:before{
            content: "";
            height:3px;
            border-radius: 1.5px;
            width:50px;
            background-color: black;
            position: absolute;
            bottom: -10px;
        }
    }

    p {
        margin-bottom: 25px;
    }

    .box {
        width: 50%;
        box-sizing: border-box;
        float: left;

        a {
            text-decoration: none;
            border: none;
            outline: none;
            display: inline-block;
            position: relative;

            .image-container{  
                position: relative; 
                cursor: pointer;
                height: 340px;
    
                img {
                    width: 100%;
                }           
            }

            .text {
                background-color: rgba(0, 0, 0, 0.75);
                width: 100%;
                padding: 20px;
                box-sizing: border-box;
                text-align: center;
                position: relative;
                margin-top: -70px;
                height: 70px;
    
                h2 {
                    font-size: 16px;
                    font-weight: 400;
                    margin: 0;
                    padding: 0;
                    color: white;
                    line-height: 30px;
                    text-decoration: none;
                    z-index: 1;
                }
            }

            &:hover{
                &:after{  
                    content: "";
                    background-color: black;                    
                    position: absolute;
                    top: 0;
                    left: 0;
                    width:100%;
                    height: 270px;
                    opacity: 0.5;                    
                    z-index: 0;
                }
            }
        }        
    }
}

@media only screen and (max-width: 1300px) {
    .home {
        padding-top: 10px;
        box-sizing: border-box;

        .slider {
            padding: 0;
            width: 100%;
            margin: 0;
        }

        .box {
            text-align: center;
            padding: 0;
    
            a {    
                .image-container {  
                    height: 242px;
                    width: 400px;
                    display: inline-block;     
                }
    
                .text {
                    margin-top: -75px;
                }   
                
                &:hover{
                    &:after{  
                        content: "";
                        background-color: black;                    
                        position: absolute;
                        top: 0;
                        left: 0;
                        width:100%;
                        height: 174px;
                        opacity: 0.5;                    
                        z-index: 0;
                    }
                }
            }        
        }
    }
}

@media only screen and (max-width: 920px) {
    .home {
        .slider {
            height: 200px;

            .intro-text {
                padding-top: 40px;
            }
        }

        .box {
            text-align: center;
            padding: 0;
            width: 100%;
    
            a {    
                .image-container {  
                    height: 242px;
                    width: 100%;
                    display: inline-block;     
                }
    
                .text {
                    margin-top: -75px;
                }   
                
                &:hover{
                    &:after{  
                        content: "";
                        background-color: black;                    
                        position: absolute;
                        top: 0;
                        left: 0;
                        width:100%;
                        height: 270px;
                        opacity: 0.5;                    
                        z-index: 0;
                    }
                }
            } 
            
            &.padding-right-25{
                padding-bottom: 30px;
            }
        }
    }
}

@media only screen and (max-width: 590px) {
    .home {
        .slider {
            background-image: none;
            height: auto;

            .intro-text {                            
                padding: 0;
                margin: 0;
                width: 100%;
                margin-top: 25px;

                p {
                  color: black;  
                  margin-bottom: 0;
                }
            }
        }

        .box {
            text-align: center;
            padding: 0;
            width: 100%;
    
            a {    
                .image-container {  
                    height: 242px;
                    width: 100%;
                    display: inline-block;     
                }
    
                .text {
                    margin-top: -75px;
                }   
                
                &:hover{
                    &:after{  
                        display: none;
                    }
                }
            } 
            
            &.padding-right-25{
                padding-bottom: 30px;
            }
        }
    }
}


@media only screen and (max-width: 440px) {
    .home {
        .box {
            a {
                .image-container {  
                    height: auto;    
                }

                .text {
                    margin-top: 0px;
                    background-color: black;
                    margin-top: -5px;
                    height: auto;
                }  
            } 
        }
    }
}

