.checkbox {
    .container {
        display: block;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;        
        width: auto;
        display: inline-block;
        margin: 0;
        padding: 0;
        
        height: 20px;
        width: 20px;
    }
              
    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
              
    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;     
        // background-color: #036;
        background-color: white;
        border: 1px solid #036;
        border-radius: 4px;
        cursor: pointer;
    }
              
 
    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark,    
    .container:hover input ~ .checkmark {
        background-color: #036;
    }
              
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
              
    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after,
    .container input:checked ~ .checkmark:after {
        display: block;
    }
              
    /* Style the checkmark/indicator */
    .container .checkmark:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}