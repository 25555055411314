@import url("https://fonts.googleapis.com/css?family=Poppins:600,400,500,200&display=swap");

body {
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    padding: 0;
    margin: 0;
    background-color: #fff;
    font-family: "Poppins", sans-serif;
    overflow-x: hidden;

    h1 {
        font-weight: 500;
    }

    h2 {
        font-weight: 400;
    }

    h3 {
        font-weight: 500;
    }

    p {
        margin: 0 0 10px;
    }

    .container {
        width: 1170px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        max-width: 100%;
    }

    .padding-right-25 {
        padding-right: 25px;
    }

    .padding-left-25 {
        padding-left: 25px;
    }

    .padding-top-20 {
        padding-top: 20px;
    }

    .padding-top-25 {
        padding-top: 25px;
    }

    .padding-top-30 {
        padding-top: 30px;
    }

    .margin-top-40 {
        margin-top: 40px;
    }

    .margin-top-30 {
        margin-top: 30px;
    }

    .single-page-title-container {
        background-image: url("../assets/images/home/slider.jpg");
        background-size: cover;
        box-sizing: border-box;
        width: 1220px;
        margin-left: -25px;
        margin-right: -25px;

        h1 {
            font-size: 28px;
            color: white;
            font-weight: 200;
            padding: 40px 25px;
        }
    }

    .clear {
        clear: both;
    }

    table {
        border-spacing: 0;
    }

    .text-right {
        text-align: right;
    }

    .text-center {
        text-align: center;
    }
}

header {
    .header-top {
        background-color: #f4f4f4;
        height: 90px;
        padding-top: 20px;
        padding-bottom: 20px;
        box-sizing: border-box;

        .logo {
            img {
                height: 50px;
                float: left;
            }

            .logo-text {
                float: left;
                margin-left: 10px;

                .main {
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 2px;
                }

                .sub {
                    margin-left: 2px;
                }
            }
        }

        .call {
            float: right;

            img {
                height: 30px;
                float: left;
                margin-top: 10px;
                margin-right: 10px;
            }

            .text {
                text-align: right;
                font-size: 16px;
                min-width: 200px;
                margin-top: 10px;
                line-height: 30px;
            }
        }
    }

    .header-bottom {
        background-color: #036;
        height: 60px;

        nav {
            padding: 0;
            margin: 0;
            border: none;

            .mobile-toggle {
                display: none;
            }

            ul {
                list-style-type: none;
                padding-left: 0;
                margin-top: 0;
                display: inline-block;

                li {
                    float: left;
                    position: relative;

                    .nav-link {
                        padding: 19px 25px;
                        display: inline-block;
                        font-size: 16px;
                        color: white;
                        text-decoration: none;
                        line-height: 22px;
                        height: 22px;
                        cursor: pointer;

                        &.dropdown-toggle {
                            img {
                                height: 15px;
                                margin-top: -4px;
                                margin-left: 5px;
                            }
                        }
                    }

                    &:hover {
                        background-color: #002850;
                        position: relative;
                        z-index: 1000;
                    }

                    &.dropdown {
                        &:hover {
                            .main-categories {
                                display: block;
                            }
                        }

                        .arrow {
                            height: 15px;
                            margin-top: -4px;
                            margin-left: 15px;
                            vertical-align: middle;
                        }

                        .main-categories {
                            display: none;
                            position: absolute;
                            width: 1170px;
                            left: -122px;
                            padding: 25px;
                            background-color: #002850;
                            font-size: 14px;
                            font-weight: 400;
                            min-height: 200px;

                            ul {
                                width: 100%;

                                li {
                                    margin: 0;
                                    float: none;
                                    width: 100%;
                                    position: unset;
                                    margin-left: -25px;
                                    padding-left: 25px;

                                    a {
                                        padding: 0;
                                        padding-top: 5px;
                                        padding-bottom: 5px;
                                        font-size: 14px;
                                    }

                                    &:hover {
                                        a {
                                            text-decoration: underline;
                                        }

                                        .details {
                                            display: block;
                                        }
                                    }

                                    .details {
                                        display: none;
                                        float: right;
                                        background-color: #f3f2f2;
                                        right: 0;
                                        top: 0;
                                        width: 950px;
                                        position: absolute;

                                        .text {
                                            float: left;
                                            width: 400px;

                                            p {
                                                padding: 25px;
                                            }
                                        }

                                        img {
                                            float: right;
                                            height: 250px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &:first-child {
                        margin-left: -25px;
                    }
                }
            }
        }

        .dropdown:hover .dropdown-menu,
        .dropdown .dropdown-menu:hover {
            transition: 0.2s;
            height: auto;
            opacity: 1;

            a {
                height: auto;
            }
        }
    }

    .nav > li > a:hover,
    .nav > li > a:focus {
        background-color: transparent;
    }
}

footer {
    background-color: #0e0e0e;
    color: #888;
    position: absolute;
    width: 100%;
    padding: 25px 0;

    p {
        margin: 0;
    }

    p.copyright {
        float: left;
    }

    p.powered-by {
        float: right;

        a {
            color: #888;
        }
    }
}

.cookie-consent {
    position: fixed;
    right: 25px;
    padding: 25px;
    bottom: 25px;
    width: 400px;
    border-radius: 3px;
    background-color: #036;
    color: white;

    a {
        margin-left: 5px;
        color: white;
    }

    button {
        padding: 8px 20px;
        font-size: 16px;
        font-weight: 300;
        background-color: white;
        color: black;
        border-radius: 4px;
        margin: 0 0 25px;
        border: 1px solid #10467d;
        margin-bottom: 0px;
        margin-top: 25px;
        cursor: pointer;
    }
}

@media only screen and (max-width: 992px) {
    header {
        .call {
            display: none;
        }
    }
}

@media only screen and (max-width: 510px) {
    header {
        .header-top {
            text-align: center;
            height: 160px;

            .logo {
                .logo-text {
                    float: none;
                }

                img {
                    float: none;
                }
            }
        }

        .call {
            display: none;
        }
    }
}

@media only screen and (max-width: 1300px) {
    .container {
        box-sizing: border-box;
    }

    header .header-bottom nav ul li.dropdown .main-categories {
        width: 700px;
        min-height: 150px;
        left: -97px;

        ul li .details {
            width: 400px;
            min-height: 200px;

            img {
                display: none;
            }
        }
    }

    body .single-page-title-container {
        max-width: 100%;
        box-sizing: border-box;
        margin: 0;
    }
}

@media only screen and (max-width: 799px) {
    header .header-bottom nav ul li.dropdown .main-categories {
        position: absolute;
        min-height: 150px;
        left: -113px;
        padding-right: 38px;
        padding-left: 15px;

        ul li:hover .details {
            display: none;
        }
    }
}

@media only screen and (max-width: 590px) {
    header .header-bottom {
        nav {
            .mobile-toggle {
                display: block;

                p {
                    font-size: 18px;
                    float: left;
                    color: white;
                    font-size: 20px;
                    top: 105px;
                    position: absolute;
                }
                .toggle {
                    position: relative;
                    float: right;
                    top: 19px;
                    right: 5px;

                    & input {
                        display: block;
                        width: 40px;
                        height: 32px;
                        position: absolute;
                        top: -7px;
                        left: -5px;
                        cursor: pointer;
                        opacity: 0;
                        z-index: 2;
                        -webkit-touch-callout: none;

                        &:checked ~ span {
                            opacity: 1;
                            transform: rotate(45deg) translate(3px, 0px);
                        }

                        &:checked ~ span:nth-last-child(1) {
                            transform: rotate(-45deg) translate(0, 0);
                        }

                        &:checked ~ span:nth-last-child(2) {
                            opacity: 0;
                            transform: rotate(0deg) scale(0.2, 0.2);
                        }

                        &:checked > ul {
                            display: inline-block;
                        }
                    }

                    & span {
                        display: block;
                        width: 33px;
                        height: 2px;
                        margin-bottom: 8px;
                        position: relative;

                        background: white;
                        border-radius: 3px;

                        z-index: 1;

                        transform-origin: 4px 0px;

                        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
                            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
                    }
                }
            }

            ul {
                display: none;
            }

            ul.mobile-opened {
                display: inline-block;
                background-color: #036;
                margin-top: 30px;
                left: 0;
                right: 0;
                padding-left: 15px;
                top: 120px;
                position: absolute;
                z-index: 1000;

                li {
                    width: 100%;
                    padding-right: 25px;
                    margin-left: -25px;

                    a {
                        width: 100%;
                    }

                    .main-categories {
                        left: 0;
                        width: 100%;
                        padding: 0;
                        position: relative;
                        padding-right: 25px;
                        min-height: 100px;
                        padding-bottom: 10px;
                        padding-top: 10px;
                        display: none;

                        ul {
                            display: inline-block;

                            li {
                                margin: 0;
                            }
                        }
                    }

                    &:hover,
                    &:active {
                        .main-categories {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 510px) {
    header .header-bottom {
        nav {
            .mobile-toggle {
                display: block;

                p {
                    font-size: 18px;
                    float: left;
                    color: white;
                    font-size: 20px;
                    top: 176px;
                    position: absolute;
                }
            }

            ul.mobile-opened {
                top: 190px;
            }
        }
    }
}

@media only screen and (max-width: 470px) {
    footer {
        .copyright {
            width: 100%;
        }
        .powered-by {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 520px) {
    .cookie-consent {
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0;
        box-sizing: border-box;
    }
}
